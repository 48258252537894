import React, { useEffect } from "react";
import Page from "./Page";
import { useSearchParams } from "react-router-dom";
import useFetch from "./useFetch";
import LoadingSection from "./LoadingSection";
import PicMeText from "./PicMeText";
import UploadImagesToPublicAlbum from "./UploadImagesToPublicAlbum";

const PublicAlbumPage = ({}) => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const { fetchData, data, error, loading } = useFetch();

  useEffect(() => {
    fetchData({
      query: "getPublicAlbumData",
      params: {
        token,
      },
    });
  }, []);

  if (loading) {
    return (
      <Page>
        <LoadingSection />
      </Page>
    );
  }

  if (!data?.event) {
    return <Page>האירוע לא נמצא</Page>;
  }

  return (
    <Page>
      <div className="page-section-padding">
        {error && <div className={"center red-text"}>{error}</div>}
        {data?.event ? <PageTop event={data.event} /> : null}
        <UploadImagesToPublicAlbum token={token} />
      </div>
    </Page>
  );
};

const PageTop = ({ event }) => {
  return (
    <div
      className={
        "d-flex-column align-items-center justify-content-center p16 mb24 border-radius"
      }
      style={{ backgroundImage: "url(/pricing-big-box.svg)" }}
    >
      <div className="center pb16 font-xl purple-dark-text">
        <span className="pl-text">העלו תמונות לאלבום השיתופי</span>
        {event.shouldUseEventName ? (
          <>
            <span className="pl-text">של</span>
            <div className="bold">{event.name}</div>
          </>
        ) : null}
      </div>

      <div className="center font-xl purple-dark-text thin">
        <span className="pl-text pr-text">הבוט של</span>
        <PicMeText />
        <span className="pr-text">
          ישלח תמונות רק לאורחים המופיעים בהן באמצעות זיהוי פנים
        </span>
      </div>
    </div>
  );
};

export default PublicAlbumPage;
