import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { toastSuccess } from "./toast";
import useFetch from "./useFetch";
import Input from "./Input";
import Page from "./Page";
import {
  LOGIN_TOKEN_KEY,
  USER_NAME,
  USER_PHONE,
  USER_ID,
  USER_ROLE,
} from "./Login";
import Loading from "./loading";

const Signup = () => {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [passwordValidate, setPasswordValidate] = useState("");
  const [clientError, setClientError] = useState("");

  const {
    fetchData: signup,
    error,
    loading,
  } = useFetch({
    method: "post",
    onSuccess: (resJson) => {
      toastSuccess("המשתמש נוצר בהצלחה!");
      if (resJson.token) {
        localStorage.setItem(LOGIN_TOKEN_KEY, resJson.token);
        localStorage.setItem(USER_NAME, resJson.userName);
        localStorage.setItem(USER_ID, resJson.userId);
        localStorage.setItem(USER_PHONE, resJson.userPhone);
        localStorage.setItem(USER_ROLE, resJson.userRole);

        navigate(`/`);
      }
    },
  });
  const onSubmit = (e) => {
    e.preventDefault();

    setClientError("");

    if (password !== passwordValidate) {
      setClientError("הסיסמאות שהוקלדו לא זהות");
      return;
    }
    signup({
      query: "signup",
      params: { password, phone, name },
    });
  };

  return (
    <Page withContactUs>
      <form className="d-flex-column align-items-center " onSubmit={onSubmit}>
        <div className="pb24">הרשמה</div>
        <Input autoFocus label="שם משתמש" value={name} setValue={setName} />
        <Input label="טלפון" value={phone} setValue={setPhone} />
        <Input label="סיסמא" value={password} setValue={setPassword} />
        <Input
          label="סיסמא שוב"
          value={passwordValidate}
          setValue={setPasswordValidate}
        />

        <div className="form-error">{error || clientError}</div>
        {loading ? (
          <div>
            <Loading />
            <div className="pt24"> יוצר סביבת משתמש חדש</div>
          </div>
        ) : (
          <button type="submit" disabled={loading}>
            אישור
          </button>
        )}
      </form>
    </Page>
  );
};

export default Signup;
